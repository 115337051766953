<template>
  <el-dialog
    v-if="form"
    v-loading="loading"
    :visible.sync="dialogVisible"
    width="80%"
    :before-close="handleClose"
  >
    <div>
      <h3><i class="anticon anticon-shop"></i> {{form.bsName}}</h3>

      <el-form
        :model="form"
        ref="form"
        label-width="120px"
        label-position="left"
      >
        <el-form-item
          class="de-i"
          label="堂食模式"
        >
          <vswitch
            onText="关闭"
            offText="开启"
            :value="form.is_TS"
            id="is_TS"
            @changeSwitch="changeSwitch"
            class="vswitch"
          />
        </el-form-item>

        <el-form-item
          class="de-i"
          label="自取模式"
        >
          <vswitch
            onText="关闭"
            offText="开启"
            :value="form.is_DB"
            id="is_DB"
            @changeSwitch="changeSwitch"
            class="vswitch"
          />
        </el-form-item>

        <el-form-item
          class="de-i"
          label="外卖模式"
        >
          <vswitch
            onText="关闭"
            offText="开启"
            :value="form.is_WM"
            id="is_WM"
            @changeSwitch="changeSwitch"
            class="vswitch"
          />
          <div>
            <el-form-item
              class="de-ii"
              label="配送平台："
            >
              <el-radio-group v-model="form.output_id">
                <el-radio
                  v-for="(item,index) in ps_list"
                  :key="index"
                  :label="item.id"
                >{{item.name}}</el-radio>
              </el-radio-group>

            </el-form-item>

            <el-form-item
              class="de-ii"
              label="配送范围："
            >

              超过 <el-input
                class="de-input"
                v-model="form.output_scope"
                type="number"
                :min="1"
              ></el-input>公里，不提供配送服务

            </el-form-item>

            <el-form-item
              class="de-ii"
              label="配送费用："
              v-if="form.output_id == 0"
            >
              <el-radio-group v-model="form.output_price_type">
                <el-radio label="1">按统一标准设置</el-radio>
                <el-radio label="2">按配送距离
                  <el-input
                    class="de-input"
                    v-model="form.output_price_body.two.sum"
                    type="number"
                    :min="1"
                  ></el-input>公里为界限，进行分段设置

                </el-radio>
              </el-radio-group>
              <!-- 类型不同数据不同 -->
              <div class="b-card" v-if="form.output_price_type" style="margin-top: 20px;">
                <div v-if="form.output_price_type == 1">
                      消费 <el-input
                    class="de-input"
                    :min="1"
                    v-model.number="form.output_price_body.one.qs_price"
                    ></el-input>元起送，配送费
                    <el-input class="de-input"
                    :min="1"
                    v-model.number="form.output_price_body.one.ps_price"
                    ></el-input>元；消费满
                    <el-input class="de-input"
                    :min="1"
                    v-model.number="form.output_price_body.one.ms_price"></el-input>元免配送费
                </div>
                <div v-if="form.output_price_type == 2">
                  <div>
                    <h4>{{form.output_price_body.two.sum}}公里范围以内:</h4>
                    消费 <el-input
                    class="de-input"
                    :min="1"
                    v-model.number="form.output_price_body.two.n.qs_price"
                    ></el-input>元起送，配送费
                    <el-input class="de-input"
                    :min="1"
                    v-model.number="form.output_price_body.two.n.ps_price"
                    ></el-input>元；消费满
                    <el-input class="de-input"
                    :min="1"
                    v-model.number="form.output_price_body.two.n.ms_price"></el-input>元免配送费
                  </div>
                  <div>
                    <h4>{{form.output_price_body.two.sum}}公里范围以外:</h4>
                     消费 <el-input
                    class="de-input"
                    :min="1"
                    v-model.number="form.output_price_body.two.w.qs_price"
                    ></el-input>元起送，配送费
                    <el-input class="de-input"
                    :min="1"
                    v-model.number="form.output_price_body.two.w.ps_price"
                    ></el-input>元；消费满
                    <el-input class="de-input"
                    :min="1"
                    v-model.number="form.output_price_body.two.w.ms_price"></el-input>元免配送费
                  </div>
                </div>
              </div>

            </el-form-item>
            <!-- 外卖配送费 -->
            <el-form-item label="配送费用："
            v-if="form.output_id != 0"
            >
            <el-input style="width:200px;"
            min="0"
            type="number"
            v-model="form.output_price_body.three.add_price" placeholder="商家额外配送服务费用"></el-input>

            </el-form-item>

            <el-form-item
              class="de-ii"
              label="配送时长："
            >

              <el-input
                style="width:100px;padding-right:10px;"
                v-model="form.output_time_length[0]"
                type="number"
                :min="1"
              ></el-input>分钟起，每增加1公里加
              <el-input
                class="de-input"
                type="number"
                :min="1"
                v-model="form.output_time_length[1]"
              ></el-input>分钟

            </el-form-item>

            <el-form-item
              class="de-ii"
              label="配送时间："
            >
              <el-radio-group v-model="form.output_time_type">
                <el-radio label="1">营业时间</el-radio>
                <el-radio label="2">固定时间
                  <el-time-picker
                    is-range
                    format="HH:mm"
                    value-format="HH:mm"
                    v-model="form.output_time"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                  >
                  </el-time-picker>
                </el-radio>
              </el-radio-group>

            </el-form-item>

            <el-form-item
              class="de-ii"
              label="接单方式："
            >

              <el-radio-group v-model="form.accept_order_type">
                <el-radio label="1">自动接单</el-radio>
                <el-radio label="2">下单后
                  <el-input
                    v-model="form.accept_order_body"
                    class="de-input"
                    type="number"
                    :min="1"
                  ></el-input>分钟内手动接单，超时自动取消
                </el-radio>
              </el-radio-group>

            </el-form-item>

            <el-form-item
              class="de-ii"
              label="取消时限："
            >

              <el-radio-group v-model="form.refund_ex_time_type">
                <el-radio label="1">配送或取餐前
                  <el-input
                    v-model="form.refund_ex_time_one"
                    class="de-input"
                    type="number"
                    :min="1"
                  ></el-input>分钟以内
                </el-radio>
                <el-radio label="2">下单后
                  <el-input
                    v-model="form.refund_ex_time_two"
                    class="de-input"
                    type="number"
                    :min="1"
                  ></el-input>分钟以内
                </el-radio>
                <el-radio label="3">不可取消</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            size="medium"
            type="primary"
            @click="onSubmit"
          >保存</el-button>
        </el-form-item>
      </el-form>

    </div>
  </el-dialog>
</template>

<script>
import StoreService from '@admin/services/StoreService'
import flatry from '@admin/utils/flatry'

import vswitch from '@admin/components/SwitchTemp'
export default {
  name: 'diningEdit',
  data () {
    return {
      dialogVisible: false,
      loading: true,
      form: null,
      ps_list: null
    }
  },

  components: {
    vswitch
  },
  methods: {
    changeSwitch (value, e) {
      switch (e) {
        case 'is_TS':
          this.form.is_TS = value
          break
        case 'is_DB':
          this.form.is_DB = value
          break
        case 'is_WM':
          this.form.is_WM = value
          break
        default:
          break
      }
    },
    async onSubmit () {
      let param = this.form

      const { data } = await flatry(StoreService.setWm(param.id, param))
      if (data) {
        this.$message.success(data.msg)
        this.$emit('refresh')
      }
    },
    async openModel (item) {
      const { data } = await flatry(StoreService.setWm(item.id))
      if (data) {
        this.ps_list = data.data.wm
        this.form = data.data.model
        this.dialogVisible = true
        this.loading = false
      } else {
        this.form = {
          output_time_length: []
        }
        this.ps_list = []
        this.$message.warning('当前数据不存在，请同步数据后重试:(')
      }
    },
    handleClose (done) {
      done()
    }
  }
}
</script>
<style lang='scss' scoped>
.vswitch {
    top: 5px;
}
.de-i {
    padding: 30px;
    border-bottom: 1px solid #e7e7e7;
    .el-form-item__label {
        font-weight: 400;
        color: red !important;
    }
}
.de-ii {
    padding: 10px 0;
}
.de-input {
    padding: 0 10px;
    width: 100px;
}
</style>
