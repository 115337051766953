<template>
    <div class="box">
        <el-button
                type="primary"
                size="medium"
                @click="getStoreData"
            >
                同步店铺数据
            </el-button>
        <div class="box-toolbar">
            <div class="box-item">
                <el-input
                    placeholder="请输入店铺名关键字"
                    v-model="bsName"
                    clearable
                    @keyup.enter.native="searchGo"
                >
                </el-input>
            </div>
            <div class="box-item">
                <el-input
                    placeholder="请输入地址关键字"
                    v-model="bsAddress"
                    clearable
                    @keyup.enter.native="searchGo"
                >
                </el-input>
            </div>
            <div class="box-item">
                <el-button
                    icon="el-icon-search"
                    circle
                    @click="searchGo()"
                ></el-button>
            </div>
        </div>
        <el-table
            height="70vh"
            v-loading="loading"
            border
            :stripe="true"
            :data="lists"
        >
            <!-- <el-table-column
                width="90"
                prop="id"
                label="Id"
            >
            </el-table-column> -->
            <el-table-column
                prop="bsName"
                label="店铺名称"
            >
            </el-table-column>
            <el-table-column
                prop="is_TS"
                label="堂食"
            >
                <template slot-scope="scope">
                    <el-switch
                        disabled
                        v-model="scope.row.is_TS"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                prop="is_DB"
                label="自取"
            >
                <template slot-scope="scope">
                    <el-switch
                        disabled
                        v-model="scope.row.is_DB"
                        active-text=""
                        inactive-text=""
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                prop="is_WM"
                label="外卖"
            >
                <template slot-scope="scope">
                    <el-switch
                        disabled
                        v-model="scope.row.is_WM"
                        active-text=""
                        inactive-text=""
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                prop="output_id"
                label="配送平台"
            >
            <template slot-scope="scope">
                <div v-if="scope.row.pes">
                    {{scope.row.pes.name || ''}}
                </div>
                <div v-else>
                    自配送
                </div>
            </template>
            </el-table-column>
            <el-table-column
                prop="output_scope"
                label="配送范围"
            >
            <template slot-scope="scope">
                <span v-if="scope.row.output_scope">
                    ≤{{scope.row.output_scope}}（公里）
                </span>
            </template>
            </el-table-column>
            <el-table-column
                prop="output_time_length"
                label="配送时间"
            >
            <template slot-scope="scope">
                <span v-if="scope.row.output_time_type == 1">
                    营业时间
                </span>
                <span v-if="scope.row.output_time_type == 2">
                    {{scope.row.output_time_s}} - {{scope.row.output_time_e}}
                </span>
            </template>
            </el-table-column>
            <el-table-column
                prop="accept_order_type"
                label="接单方式"
            >
             <template slot-scope="scope">
            <span  v-if="scope.row.accept_order_type">
               {{scope.row.accept_order_type == 1?'自动':'手动'}}
               </span>
            </template>
            </el-table-column>
            <el-table-column
                width="150"
                label="操作"
            >
                <template slot-scope="scope">
                    <el-button
                        type="primary"
                        size="mini"
                        plain
                        @click="editInfo(scope.row)"
                    >编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <page
            :pageSize="limit"
            :count="count"
            @change="pageChange"
            @changeSize="changeSize"
        >
        </page>
        <diningEdit ref="info" @refresh="searchGo"/>
    </div>
</template>

<script>
import StoreService from '@admin/services/StoreService'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'
import diningEdit from './diningEdit'
import flatry from '@admin/utils/flatry'

export default {
  components: {
    Page, diningEdit
  },
  data () {
    return {
      loading: true,
      function: function (param) {
        return StoreService.allSetList(param)
      },
      bsName: '',
      bsAddress: ''
    }
  },
  mixins: [pagination],
  methods: {
    // 获取数据
    async searchGo () {
      this.page = 1
      let param = {
        bsName: this.bsName,
        bsAddress: this.bsAddress
      }
      this.where = param
      await this.getList(param)
    },
    editInfo (row) {
      this.$refs.info.openModel(row)
    //   let url = '/store/set-edit'
    //   this.$router.push({ path: url, query: { id: row.id } })
    },
    async getStoreData () {
      this.loading = true
      const { data } = await flatry(StoreService.tbData())
      if (data) {
        this.$message.success(data.msg)
        await this.searchGo()
      }
      this.loading = false
    }
  }
}
</script>
