<template>
  <div
    class="weui-div"
    @click.stop="toggle"
  >
    <span
      class="weui-switch"
      :class="{'weui-switch-on' : me_checked}"
      :value="value"
    ></span>
    <div
      v-if='!me_checked'
      :class="'weui-div-2'"
    >{{onText}}</div>
    <div
      v-if='me_checked'
      :class="'weui-div-1 '  + (me_checked?'on':'') "
    >{{offText}}</div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    onText: {
      type: String,
      default: '未处理'
    },
    offText: {
      type: String,
      default: '已处理'
    },
    id: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: true
    },
    disbale: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      me_checked: this.value
    }
  },
  watch: {
    value () {
      this.me_checked = this.value
    }
  },
  methods: {
    toggle () {
      // 是否可点击
      if (this.disbale === true) {
        this.me_checked = !this.me_checked
        this.$emit('changeSwitch', this.me_checked, this.id)
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.weui-div {
    position: relative;
    font-weight: bold;
    cursor: pointer;
    width: 82px;
    height: 32px;
    font-family:PingFangSC;
    font-weight:400;
}
.weui-div-1 {
    position: absolute;
    left: 7px;
    top: 0;
    line-height: 32px;
    font-size: 12px;
    color: #333333;
}
.weui-div-2 {
    position: absolute;
    right: 7px;
    top: 0;
    line-height: 32px;
    font-size: 12px;
    color: #999999;
}
.weui-switch {
    display: block;
    position: relative;
    width: 82px;
    height: 32px;
    border: 1px solid #EBEBEB;
    outline: 0;
    // border-radius: 16px;
    box-sizing: border-box;
    background-color: #EBEBEB;
    transition: background-color 0.1s, border 0.1s;
    cursor: pointer;
}
.weui-switch:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 30px;
    // border-radius: 15px;
    background-color: #e6e6e6;
    transition: transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
}
.weui-switch:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 30px;
    // border-radius: 15px;
    background-color: #ffffff;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    transition: transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
}
.on{
    color: white;
}
.weui-switch-on {
    border-color: #1265ff;
    background-color: #1265ff;
}
.weui-switch-on:before {
    border-color: #1265ff;
    background-color: #1265ff;
}
.weui-switch-on:after {
    transform: translateX(40px);
}
</style>
